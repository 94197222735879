import React from "react";
import { Helmet } from "react-helmet";


const About = ()=> {

	return (
		<><Helmet>
			<meta charSet="utf-8" />
			<title>О проекте</title>
			<meta name="description" content="Информация о проекте Apofeouz. Когда всё началось и что будет дальше" />
		</Helmet><div className="container">

				<h2 className="text-center title">О проекте</h2>
				<br />
				<div className="row">
					<div className="col-sm-4 col-sm-offset-2">
						<h5><strong>Когда всё начилось?<br /></strong></h5>
						<p>Скорее всего в ранней юности, лет в 12. Ко мне подошёл мой друг Евгений Кушнарёв и предложил собрать музыкальный коллектив и играть музыку.
							Безусловно представления были далеки о том, как и что надо делать, но энтузиазма было много.
							Я упросил свою матушку купить мне гитару, это была шестиструнная акустическая гитара фабрики Бобров.
							В моём же гараже сделали импровизированную барабанную установку из кастрюль и коробок. И да скорее всего это и было начало творческого пути.</p>
						<p>Название Apofeouz было придумано во время чтение какого то журнала, где была размещена фотография картины Василия Васильевича Верещагина "Апофеоз войны"
							Я помню, оно произвело на меня сильное впечатления, особенно название, посмотрев, что означает это слово в словаре и как оно пишется на латыне, я тогда сразу для себя понял, что данное название очень хорошо будет подходить к нашему проекту.</p>
						<p>Более серьёзные продвижение в музыкальном направление произошли ближе к 1997-му году, когда я познакомился с Беликовым Владимиром, который больше знал и умел в плане создании музыки и игры на музыкальных инструментах, а так же имел опыт выступлений на сцене. Под его началом я освоил бас-гитару и разнообразил технику игры на гитаре. Выступали мы в местом ДК в городе Липки Тульской области. Так же были выезды и в соседние районы.
							К сожалению в те времена с записывающий аппаратурой было всё сложно и ни каких записей или видео материалов не осталось.</p>
						<p>2001 году я переехал в другой город и до 2004 создавал несколько составов c Черненко Екатериной, в основном играя свои песни. Так же были несколько успешных акустических проектов с Шеррер Александром, мы выступали в различных конкурсах авторской песни и часто выигрывали первые места. На одном из таких областных конкурсов нас наградили грамотами лауреатов и даже получили, какие то призы. Но ближе к 2004 его забрали в армию.</p>
						<p>Серьёзная же концерта деятельность началась, когда познакомился с Щербаковым Валерием, он играл на барабанах в местной группе Чеширский кот. Мне предложили место второй гитары и по возможности играть сольные партии. Так как я особо этого не умею играл, так как получалось, но было интересно. Здесь я получил большой опыт в создании каверов на популярные песни. Так же было написано и сыграно несколько песен собственного сочинения.
							В основном мы выступали в местных клубах. Были разовые выступление на свадьбах и открытия автосалона. По разным причинам деятельность в этом коллективе пришлось приостановить.</p>
						<br />
					</div>
					<div className="col-sm-4">
						<h5><strong>Что будет дальше?<br /></strong></h5>
						<p>Сейчас во времена компьютеров и быстрого интернета вся музыкальная деятельность перебралась в сеть.
							Теперь можно записывать аудио, создавать видеоролики за своим компьютером и не привязаться ни к чему.</p>
						<p>За это время я познакомился с отличными ребятами, которые так же как и я увлечены музыкой.
							Мы можем находится в разных городах, на любых растояниях и при этом делать отличные вещи.
							На сегодня я участвую в аранжировках для своих друзей и новых знакомых. Записываю некоторые песни из собственного сочинения, а так же продолжаю делать каверы на старые популярные песни, для своей матушки, которая когда купила для меня первую гитару.
							На сегодня таких работ скопилось достаточно много, что бы создать этот сайт и делится проектами с публикой.</p>
						<p>Всё это для меня проект Apofeouz, который когда начался в гараже возле дома с одной гитарой.</p>
						<h5><strong>Мои ссылки<br /></strong></h5>
						<p><a href="https://vk.com/apofeouzmusic">VK</a> / <a href="https://www.youtube.com/c/Juliaplusmusic">YouTube</a> / </p>
					</div>
				</div>
			</div></>
	


	);
}

export default About;